import React, { FC, SVGProps } from 'react'

import { colors } from '#styles'

type Props = {
  black?: boolean
} & SVGProps<SVGSVGElement>

const Globe: FC<Props> = ({ black, ...props }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0ZM2.25203 8C2.08751 8.63924 2 9.3094 2 10C2 10.6906 2.08751 11.3608 2.25203 12H5.09432C5.03228 11.3521 5 10.6829 5 10C5 9.31712 5.03228 8.64793 5.09432 8H2.25203ZM3.07026 6H5.39317C5.60515 4.9765 5.89762 4.04022 6.25776 3.2299C6.31802 3.09431 6.38064 2.96122 6.44561 2.83099C5.03292 3.53275 3.8571 4.63979 3.07026 6ZM7.10446 8C7.03652 8.63758 7 9.3072 7 10C7 10.6928 7.03652 11.3624 7.10446 12H12.8955C12.9635 11.3624 13 10.6928 13 10C13 9.3072 12.9635 8.63758 12.8955 8H7.10446ZM12.5584 6H7.44164C7.61531 5.26765 7.83379 4.60826 8.08538 4.04218C8.41337 3.30422 8.77801 2.76892 9.1324 2.43166C9.48164 2.0993 9.7731 2 10 2C10.2269 2 10.5184 2.0993 10.8676 2.43166C11.222 2.76892 11.5866 3.30422 11.9146 4.04218C12.1662 4.60826 12.3847 5.26765 12.5584 6ZM14.9057 8C14.9677 8.64793 15 9.31712 15 10C15 10.6829 14.9677 11.3521 14.9057 12H17.748C17.9125 11.3608 18 10.6906 18 10C18 9.3094 17.9125 8.63924 17.748 8H14.9057ZM16.9297 6H14.6068C14.3949 4.9765 14.1024 4.04022 13.7422 3.2299C13.682 3.09431 13.6194 2.96122 13.5544 2.83099C14.9671 3.53275 16.1429 4.63979 16.9297 6ZM6.44561 17.169C5.03292 16.4672 3.85709 15.3602 3.07026 14H5.39317C5.60515 15.0235 5.89762 15.9598 6.25776 16.7701C6.31802 16.9057 6.38064 17.0388 6.44561 17.169ZM8.08538 15.9578C7.83379 15.3917 7.61531 14.7324 7.44164 14H12.5584C12.3847 14.7324 12.1662 15.3917 11.9146 15.9578C11.5866 16.6958 11.222 17.2311 10.8676 17.5683C10.5184 17.9007 10.2269 18 10 18C9.7731 18 9.48164 17.9007 9.1324 17.5683C8.77801 17.2311 8.41337 16.6958 8.08538 15.9578ZM13.7422 16.7701C14.1024 15.9598 14.3949 15.0235 14.6068 14H16.9297C16.1429 15.3602 14.9671 16.4672 13.5544 17.169C13.6194 17.0388 13.682 16.9057 13.7422 16.7701Z"
      fill={black ? colors.black : colors.blueSolitudeLight}
    />
  </svg>
)

export default Globe
