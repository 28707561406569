export const colors = {
  white: '#FFFFFF',
  whiteGray: '#E7E0D8',
  black: '#000000',
  redTorch: '#FF024A',
  grayPayne: '#474748',
  graySilver: '#C2C2C2',
  greenHummingbird: '#CDF0E3',
  blueSea: '#CDE7F0',
  blueLinkWater: '#D1D5DB',
  blueSolitudeLight: '#F3F4F6',
  greenAccent: '#36C290',
  blueAccent: '#369FC2',
  redAccent: '#AD0E2E',
  orangeAccent: '#E1482B',
  blackAccent: '#152230',
  AccentYellow: '#F2AD2D',
  brownAccent: '#D7B687',
  pinkPeach: '#F8D1CA',
  yellowCorn: '#FCEACA',
  blackTransparent: 'rgba(0, 0, 0, 0.8)',

  primaryBlack300: '#212223',
  primary600: '#FF024A',
  primaryBlack900: '#111111',

  neutral50: '#F9FAFB',
  neutral200: '#E5E7EB',
  neutral400: '#9CA3AF',
  neutral600: '#4B5563',
  neutral700: '#374151',
  neutral900: '#111827',
} as const
