export const fontWeight = {
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  extraBold: 800,
} as const

export const typography = {
  //72px
  display2Xl: {
    regular: {
      fontSize: '4.5rem',
      lineHeight: '5.625rem',
      fontWeight: fontWeight.regular,
    },
    medium: {
      fontSize: '4.5rem',
      lineHeight: '5.625rem',
      fontWeight: fontWeight.medium,
    },
    semiBold: {
      fontSize: '4.5rem',
      lineHeight: '5.625rem',
      fontWeight: fontWeight.semiBold,
    },
    bold: {
      fontSize: '5rem',
      lineHeight: '5.625rem',
      fontWeight: fontWeight.extraBold,
    },
  },
  //60px
  displayXl: {
    regular: {
      fontSize: '3.75rem',
      lineHeight: '4.5rem',
      fontWeight: fontWeight.regular,
    },
    medium: {
      fontSize: '3.75rem',
      lineHeight: '4.5rem',
      fontWeight: fontWeight.medium,
    },
    semiBold: {
      fontSize: '3.75rem',
      lineHeight: '4.5rem',
      fontWeight: fontWeight.semiBold,
    },
    bold: {
      fontSize: '3.75rem',
      lineHeight: '4.5rem',
      fontWeight: fontWeight.extraBold,
    },
  },
  //48px
  displayLg: {
    regular: {
      fontSize: '3rem',
      lineHeight: '3.75rem',
      fontWeight: fontWeight.regular,
    },
    medium: {
      fontSize: '3rem',
      lineHeight: '3.75rem',
      fontWeight: fontWeight.medium,
    },
    semiBold: {
      fontSize: '3rem',
      lineHeight: '3.75rem',
      fontWeight: fontWeight.semiBold,
    },
    bold: {
      fontSize: '3rem',
      lineHeight: '3.75rem',
      fontWeight: fontWeight.extraBold,
    },
  },
  //36px
  displayMd: {
    regular: {
      fontSize: '2.25rem',
      lineHeight: '3rem',
      fontWeight: fontWeight.regular,
    },
    medium: {
      fontSize: '2.25rem',
      lineHeight: '3rem',
      fontWeight: fontWeight.medium,
    },
    semiBold: {
      fontSize: '2.25rem',
      lineHeight: '3rem',
      fontWeight: fontWeight.semiBold,
    },
    bold: {
      fontSize: '2.25rem',
      lineHeight: '3rem',
      fontWeight: fontWeight.extraBold,
    },
  },
  //30px
  displaySm: {
    regular: {
      fontSize: '1.875rem',
      lineHeight: '2.5rem',
      fontWeight: fontWeight.regular,
    },
    medium: {
      fontSize: '1.875rem',
      lineHeight: '2.5rem',
      fontWeight: fontWeight.medium,
    },
    semiBold: {
      fontSize: '1.875rem',
      lineHeight: '2.5rem',
      fontWeight: fontWeight.semiBold,
    },
    bold: {
      fontSize: '1.875rem',
      lineHeight: '2.5rem',
      fontWeight: fontWeight.extraBold,
    },
  },
  //24px
  displayXs: {
    regular: {
      fontSize: '1.5rem',
      lineHeight: '2rem',
      fontWeight: fontWeight.regular,
    },
    medium: {
      fontSize: '1.5rem',
      lineHeight: '2rem',
      fontWeight: fontWeight.medium,
    },
    semiBold: {
      fontSize: '1.5rem',
      lineHeight: '2rem',
      fontWeight: fontWeight.semiBold,
    },
    bold: {
      fontSize: '1.5rem',
      lineHeight: '2rem',
      fontWeight: fontWeight.extraBold,
    },
  },
  //20px
  bodyXl: {
    regular: {
      fontSize: '1.25rem',
      lineHeight: '1.75rem',
      fontWeight: fontWeight.regular,
    },
    medium: {
      fontSize: '1.25rem',
      lineHeight: '1.75rem',
      fontWeight: fontWeight.medium,
    },
    semiBold: {
      fontSize: '1.25rem',
      lineHeight: '1.75rem',
      fontWeight: fontWeight.semiBold,
    },
    bold: {
      fontSize: '1.25rem',
      lineHeight: '1.75rem',
      fontWeight: fontWeight.extraBold,
    },
  },
  //18px
  bodyLg: {
    regular: {
      fontSize: '1.125rem',
      lineHeight: '1.75rem',
      fontWeight: fontWeight.regular,
    },
    medium: {
      fontSize: '1.125rem',
      lineHeight: '1.75rem',
      fontWeight: fontWeight.medium,
    },
    semiBold: {
      fontSize: '1.125rem',
      lineHeight: '1.75rem',
      fontWeight: fontWeight.semiBold,
    },
    bold: {
      fontSize: '1.125rem',
      lineHeight: '1.75rem',
      fontWeight: fontWeight.extraBold,
    },
  },
  //16px
  bodyMd: {
    regular: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
      fontWeight: fontWeight.regular,
    },
    medium: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
      fontWeight: fontWeight.medium,
    },
    semiBold: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
      fontWeight: fontWeight.semiBold,
    },
    bold: {
      fontSize: '1rem',
      lineHeight: '1.5rem',
      fontWeight: fontWeight.extraBold,
    },
  },
  //14px
  bodySm: {
    regular: {
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      fontWeight: fontWeight.regular,
    },
    medium: {
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      fontWeight: fontWeight.medium,
    },
    semiBold: {
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      fontWeight: fontWeight.semiBold,
    },
    bold: {
      fontSize: '0.875rem',
      lineHeight: '1.25rem',
      fontWeight: fontWeight.extraBold,
    },
  },
  //12px
  bodyXs: {
    regular: {
      fontSize: '0.75rem',
      lineHeight: '1rem',
      fontWeight: fontWeight.regular,
    },
    medium: {
      fontSize: '0.75rem',
      lineHeight: '1rem',
      fontWeight: fontWeight.medium,
    },
    semiBold: {
      fontSize: '0.75rem',
      lineHeight: '1rem',
      fontWeight: fontWeight.semiBold,
    },
    bold: {
      fontSize: '0.75rem',
      lineHeight: '1rem',
      fontWeight: fontWeight.extraBold,
    },
  },
} as const
