import Image from 'next/image'
import styled from 'styled-components'

import { colors, dimensions, styling, typography } from '#styles'

export const ComponentContainer = styled.div`
  width: 100%;
  background-color: ${colors.black};
`

export const ComponentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0 2rem;
  @media (min-width: ${dimensions.tablet}) {
    flex-direction: row;
  }
`

export const ImageContainer = styled.figure`
  flex: 1;
`

export const StyledImage = styled(Image)`
  object-fit: cover;
  width: 100%;
  max-height: 100%;
  height: auto;
`

export const FormContainer = styled.div`
  flex: 1;
`

export const TextWrapper = styled.div`
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${styling.container.gap.lg};
  padding: 2rem;
  @media (min-width: ${dimensions.tablet}) {
    justify-content: flex-start;
    align-items: flex-start;
  }
`

export const TextHeader = styled.h1`
  ${typography.displaySm.bold}
  white-space: pre-line;
  color: ${colors.neutral50};
`

export const TextContainer = styled.article`
  display: flex;
  flex-direction: column;
`

export const TextBody = styled.section`
  font-size: ${typography.bodyMd.regular.fontSize};
  font-weight: ${typography.bodyMd.regular.fontWeight};
  line-height: ${typography.bodyMd.regular.lineHeight};
  color: ${colors.neutral50};
  opacity: 0.7;
`

export const Input = styled.input`
  height: 96%;
  width: 96%;
  border-radius: 0.5rem;
  transition: 0.3s all;
  font-size: 1rem;
  border: 0;
  padding: 0 0.5rem;
  background-color: ${colors.white};
  &:focus {
    border: 0;
    outline: 0;
    height: 100%;
    width: 100%;
  }
`
