import Image from 'next/image'
import Link from 'next/link'
import styled, { css } from 'styled-components'

import { colors, dimensions, styling, typography } from '#styles'

export const FooterContainer = styled.footer`
  display: flex;
  justify-content: center;
  background-color: ${colors.black};
`

export const FooterWrapper = styled.div`
  flex-direction: column;
  display: flex;
  width: 100%;
  max-width: ${styling.container.maxWidth.normal};
  justify-content: space-between;
  align-items: center;
  color: white;
  padding: ${styling.wrapper.mobilePadding};
`

export const CopyrightText = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  color: ${colors.graySilver};
  font-size: 0.75em;
  ${typography.bodyXs.regular}
`

export const SocialImage = styled(Image)`
  display: flex;
`

export const SocialContainer = styled.figure`
  display: flex;
  flex: 1;
  flex-direction: row;
  gap: 1rem;
  justify-content: flex-end;
`

export const SocialLink = styled.a`
  height: 3rem;
  width: 3rem;
  background: black;
  border-radius: 0.2rem;
  display: grid;
  place-items: center;
  &:hover {
    transition: 0.3s all ease-in-out;
    background-color: ${colors.redTorch};
  }
`

export const BottomContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  @media (min-width: ${dimensions.tablet}) {
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
`

export const DividerContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  @media (min-width: ${dimensions.tablet}) {
    padding: 0 4rem;
  }
  @media (min-width: ${dimensions.laptop}) {
    padding: 0;
  }
`

export const DividerLeft = styled.div`
  margin: 0 2rem 0 1rem;
  height: 1px;
  flex: 1;
  background-color: ${colors.grayPayne};
`

export const DividerRight = styled.div`
  margin: 0 1rem 0 2rem;
  height: 1px;
  flex: 1;
  background-color: ${colors.grayPayne};
`

export const LinksContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
  gap: 1rem;
  margin: 2rem 0;

  @media (min-width: ${dimensions.tablet}) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`

export const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;

  &:nth-child(2),
  :nth-child(4) {
    align-items: end;
    text-align: end;
  }

  @media (min-width: ${dimensions.tablet}) {
    &:nth-child(2),
    :nth-child(4) {
      align-items: start;
      text-align: start;
    }
  }
`

const LinkCss = css`
  ${typography.bodySm.regular}
  color: ${colors.white};
  transition: all 0.3s ease-in-out;
  display: flex;
  padding: ${styling.button.padding.sm};
  &:hover {
    background-color: ${colors.redTorch};
    color: ${colors.white};
  }
  :first-child {
    ${typography.bodyXl.semiBold};
  }
`

export const NavLink = styled(Link)`
  ${typography.bodyXl.semiBold};
  position: relative;
  display: flex;
  padding: ${styling.button.padding.sm};
  color: ${colors.white};
  border-radius: 0.2rem;
  transition: all 0.3s ease-in-out;

  @media (min-width: ${dimensions.laptop}) {
    &:after {
      content: '';
      position: absolute;
      top: 100%;
      z-index: 100;
      width: 0;
      border-bottom: 2px solid ${colors.redTorch};
      transition: 0.3s ease-in-out;
    }

    &:hover:after {
      width: 100%;
      border-radius: 0.2rem;
      margin: 0 auto;
    }
  }
`

export const SubNavLink = styled(Link)`
  border-radius: 0.2rem;

  ${LinkCss}
`

export const ExternalLink = styled.a`
  ${LinkCss}
`
