type NonNull<Type> = {
  [Property in keyof Type]: NonNullable<Type[Property]>
}

const env = {
  INTERCOM_APP_ID: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
  API_URL: process.env.NEXT_PUBLIC_BRICK_URL,
  X_API_KEY: process.env.NEXT_PUBLIC_BRICK_X_API_KEY,
  GTM_CONTAINER_ID: process.env.NEXT_PUBLIC_GTM_CONTAINER_ID,
  USE_PHRASE: process.env.NEXT_PUBLIC_USE_PHRASE ?? 'false',
  GOOGLE_MAPS_KEY: process.env.NEXT_PUBLIC_GOOGLE_MAPS_KEY,
  COOKIEYES_ID: process.env.NEXT_PUBLIC_COOKIEYES_ID,
} as const

type NonNullEnv = NonNull<typeof env>

const missingVars = Object.entries(env).filter(([_key, val]) => val == null)

if (missingVars.length > 0) {
  const error = `missing environmental variables:\n${missingVars
    .map(([key]) => key)
    .join('\n')}`
  throw new Error(error)
}

export default env as NonNullEnv
