import Hamburger from 'hamburger-react'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'
import { FC, useEffect, useState } from 'react'

import { colors } from '#styles'

import BrickLogo from '../../../../public/assets/common/brick-logo.svg'
import { Paths } from '../../../lib/constants'
import { getAppLink } from '../../../lib/helpers'
import {
  CtaItem,
  NavigationItem,
  useNavigationLinksCta,
  useNavigationLinksHeader,
} from '../../../lib/hooks/useNavigationLinks'
import { PageProps } from '../../../types/pages'
import ConditionalContainer from '../../common/ConditionalContainer/ConditionalContainer'
import LinkButton from '../../common/LinkButton/LinkButton'
import NavButton from '../../common/NavButton/NavButton'
import CtaTab from '../CallToAction/CallToAction'
import DropdownMenu from '../DropdownMenu/DropdownMenu'
import LanguageDropdown from '../LanguageDropdown/LanguageDropdown'
import MobileNavMenu from '../MobileNavMenu/MobileNavMenu'
import { UnderlinedLinkItem } from '../MobileNavMenu/styled'
import * as S from './styled'

type Props = {
  applessOnly: boolean
} & Pick<PageProps, 'button'>

const Menubar: FC<Props> = ({ applessOnly, button }) => {
  const navItems = useNavigationLinksHeader()
  const { t } = useTranslation('common')

  const ctaItems = useNavigationLinksCta()

  const [hamburgerIsOpen, setHamburgerIsOpen] = useState(false)

  useEffect(() => {
    if (hamburgerIsOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [hamburgerIsOpen])

  const renderLinks = (links: NavigationItem[]) =>
    links.map(link => (
      <DropdownMenu
        key={link.label}
        label={link.label}
        path={link.path}
        menuItems={link.menuItems}
      />
    ))

  const renderMobileLinks = (links: (NavigationItem | CtaItem)[]) =>
    links.map(link => {
      if ('menuItems' in link) {
        return (
          <MobileNavMenu
            key={link.label}
            label={link.label}
            path={link.path}
            menuItems={link.menuItems}
            setHamburgerIsOpen={setHamburgerIsOpen}
          />
        )
      } else if ('label' in link) {
        return (
          <UnderlinedLinkItem key={link.label} href={link.path}>
            {link.label}
          </UnderlinedLinkItem>
        )
      }
      return null
    })
  const isPartnership = button === 'APPLY_PARTNER'

  return (
    <S.ComponentContainer>
      <S.CtaWrapper>
        <ConditionalContainer laptop style={{ width: '100%' }}>
          <CtaTab />
        </ConditionalContainer>
      </S.CtaWrapper>
      <S.ComponentWrapper>
        <Link href={Paths.HOME} prefetch={false}>
          <S.LogoImage
            src={BrickLogo}
            priority
            width={84}
            height={42}
            alt={t('navigation.logo_alt')}
          />
        </Link>
        <S.NavLinksContainer>{renderLinks(navItems)}</S.NavLinksContainer>

        <S.GeoLanguageContainer>
          <S.GeoItem>
            <LanguageDropdown />
          </S.GeoItem>
        </S.GeoLanguageContainer>
        <S.ButtonContainer
          laptop
          tablet
          style={{ marginLeft: 'auto', paddingRight: '22px' }}
        >
          {isPartnership ? (
            <NavButton
              href={Paths.PARTNERSHIP_APPLY}
              buttonColor={colors.redTorch}
              size="small"
              id="nav-apply-book_mo"
            >
              {t('navigation.link_monday_partnership')}
            </NavButton>
          ) : (
            <LinkButton
              href={getAppLink(applessOnly)}
              id="nav-get-in-touch"
              size="small"
              buttonColor={colors.redTorch}
            >
              {t('navigation.link_app_store')}
            </LinkButton>
          )}
        </S.ButtonContainer>

        <ConditionalContainer tablet mobile>
          <div style={{ marginTop: '-2px' }}>
            <Hamburger
              color="white"
              toggled={hamburgerIsOpen}
              toggle={setHamburgerIsOpen}
              label="hamburger menu"
            />
          </div>
          {hamburgerIsOpen && (
            <S.HamburgerOverlay>
              <S.OverlayContainer>
                {renderMobileLinks([...ctaItems, ...navItems])}
                <S.OverlayAppLink>
                  {isPartnership ? (
                    <NavButton
                      id="nav-apply-book_mo"
                      href={Paths.PARTNERSHIP_APPLY}
                      buttonColor={colors.redTorch}
                      style={{ width: '100%' }}
                      size="small"
                    >
                      {t('navigation.link_monday_partnership')}
                    </NavButton>
                  ) : (
                    <LinkButton
                      id="nav-get-in-touch"
                      href={getAppLink(applessOnly)}
                      size="small"
                      style={{ width: '100%' }}
                      buttonColor={colors.redTorch}
                    >
                      {t('navigation.link_app_store')}
                    </LinkButton>
                  )}
                </S.OverlayAppLink>
              </S.OverlayContainer>
            </S.HamburgerOverlay>
          )}
        </ConditionalContainer>
      </S.ComponentWrapper>
    </S.ComponentContainer>
  )
}

export default Menubar
