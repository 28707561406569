//16px root font size
export const styling = {
  button: {
    borderRadius: '0.5rem',
    padding: {
      sm: '0.5rem 0.75rem',
      md: '1rem 1.375rem',
    },
  },
  wrapper: {
    mobilePadding: '3rem 1.5rem',
    tabletPadding: {
      sm: '5rem 2.5rem',
      lg: '5rem 5rem',
    },
    laptopPadding: {
      sm: '6rem 2.5rem',
      md: '4rem 7rem',
      lg: '5rem 7rem',
      xl: '6rem 7rem',
      '2xl': '8rem 7rem',
    },
  },
  container: {
    mobilePadding: '3rem 0',
    maxWidth: {
      normal: '80rem', //1280px
      semi: '95rem', //1500px
    },

    gap: {
      xs: '0.5rem',
      sm: '1rem',
      md: '1.5rem',
      lg: '2rem',
      xl: '2.5rem',
      '2xl': '3rem',
      '3xl': '5rem',
    },
  },
  navigation: {
    height: '5rem',
  },
  imageWrapper: {
    borderRadius: '.5rem',
  },
} as const

export const dimensions = {
  mobile: '23.5rem',
  tablet: '48rem',
  laptop: '75rem',
  desktop: '112rem',
} as const

export const device = {
  mobile: 'min-width: 23.5rem',
  tablet: 'min-width: 48rem',
  laptop: 'min-width: 75rem',
} as const

export const imageSizes = {
  hero: {
    laptop: '40rem',
  },
  shadowed: {
    tablet: '29rem',
    laptop: '37rem',
  },
  section: {
    mobileSm: '16rem',
    mobile: '21rem',
    tablet: '25rem',
    laptop: '26.25rem',
    laptopL: '33.25rem',
    full: '100%',
  },
} as const
