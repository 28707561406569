import '../styles/globals.css'

import type { AppProps } from 'next/app'
import { Montserrat } from 'next/font/google'
import Script from 'next/script'
import { appWithTranslation } from 'next-i18next'
import { Intercom, LiveChatLoaderProvider } from 'react-live-chat-loader'

import locales from '../../locales.config'
import Layout from '../components/Layout/Layout'
import env from '../lib/env'
import { PageProps } from '../types/pages'

if (process.env.NODE_ENV !== 'production') {
  const consoleError = console.error

  // disables some stupid warnings about prop names
  console.error = function filterWarnings(msg) {
    const suppressedWarnings = ['Warning: React does not recognize the']

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
    if (!suppressedWarnings.some(entry => msg?.includes?.(entry))) {
      // @ts-expect-error - doesn't matter
      // eslint-disable-next-line prefer-rest-params
      consoleError.apply(console, arguments)
    }
  }
}
const montserrat = Montserrat({
  preload: true,
  subsets: ['latin'],
})

const gtmScript = `function gtag(){dataLayer.push(arguments)}window.dataLayer=window.dataLayer||[],gtag("consent","default",{ad_user_data:"denied",ad_personalization: "denied",ad_storage:"denied",analytics_storage:"denied",functionality_storage:"denied",personalization_storage:"denied",security_storage:"granted",wait_for_update:2e3}),gtag("set","url_passthrough",!0),gtag("set","ads_data_redaction",!0),function(e,t,a,n,s){e[n]=e[n]||[],e[n].push({"gtm.start":new Date().getTime(),event:"gtm.js"});var g=t.getElementsByTagName(a)[0],r=t.createElement(a);r.async=!0,r.src="https://www.googletagmanager.com/gtm.js?id="+s+("dataLayer"!=n?"&l="+n:""),g.parentNode.insertBefore(r,g)}(window,document,"script","dataLayer","${env.GTM_CONTAINER_ID}");`

const MyApp = ({ Component, pageProps }: AppProps<PageProps>) => {
  return (
    <>
      <Script id="gtm-script" strategy="afterInteractive">
        {gtmScript}
      </Script>
      <style jsx global>{`
        html {
          font-family: ${montserrat.style.fontFamily};
        }
      `}</style>
      <LiveChatLoaderProvider
        providerKey={env.INTERCOM_APP_ID}
        provider="intercom"
      >
        <Layout {...pageProps}>
          <Component {...pageProps} />
          <Intercom color="black" />
        </Layout>
      </LiveChatLoaderProvider>
    </>
  )
}

export default appWithTranslation(MyApp, {
  i18n: {
    defaultLocale: 'en-us',
    locales: locales.slice(1),
  },
  returnObjects: true,
  appendNamespaceToMissingKey: true,
  lowerCaseLng: true,
})
