import Link from 'next/link'
import styled from 'styled-components'

import { colors, dimensions, typography } from '#styles'

export const LanguageText = styled.section`
  width: 100%;
  padding: 0.5rem 1rem;
  ${typography.bodySm.semiBold};
  color: ${colors.white};
  @media (min-width: ${dimensions.laptop}) {
    color: ${colors.blueSolitudeLight};
  }
`

export const Container = styled.div`
  color: ${colors.black};
  ${typography.bodySm.semiBold}
  align-items: flex-start;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  @media (min-width: ${dimensions.laptop}) {
    &:hover {
      background-color: ${colors.redTorch};
    }
  }
`

export const OpenMenuButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${typography.bodySm.medium};
  color: ${colors.blueSolitudeLight};
  min-width: 7rem;
  transition: all 0.3s;
  z-index: 3;
  width: 100%;
  padding: 0 0.5rem;

  @media (min-width: ${dimensions.laptop}) {
    &:hover {
      background-color: ${colors.redTorch};
      border-radius: 0.2rem;
    }
  }
`

export const ClickOutside = styled.div<{ open: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  height: ${props => (props.open ? '100%' : '0%')};
  width: 100%;
  z-index: 3;
  cursor: default;
`

export const Options = styled.ul<{
  open: boolean
  reverse?: boolean
}>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  max-height: ${({ open }) => (open ? '50rem' : '0rem')};
  ${({ reverse }) => (reverse ? `bottom: 100%` : `top: 100%`)};
  right: 0;
  max-width: 50rem;
  min-width: 100%;
  z-index: 3;
  transition: ${({ open }) =>
    open
      ? 'max-height 0.15s 0s, opacity 0.15s 0s, box-shadow 0.15s 0s'
      : 'max-height 0.15s 0.3s, opacity 0.15s 0.3s, box-shadow 0.15s 0.3s'};
  overflow: ${props => (props.open ? 'visible' : 'hidden')};
  @media (min-width: ${dimensions.laptop}) {
    background-color: ${colors.black};
  }
`

export const LanguageLink = styled(Link)<{
  open: boolean
  position: number
  options: number
}>`
  width: 100%;
  display: flex;
  align-items: flex-start;
  background-color: black;
  transition: ${({ open, position, options }) => {
    const time = 0.15 * (open ? position : options - position)
    return `background-color 0.3s, opacity ${time}s`
  }};
  &:hover {
    background-color: ${colors.primary600};
  }
`
