import Image from 'next/image'
import styled from 'styled-components'

import { colors, dimensions, styling, typography } from '#styles'

import ConditionalContainer from '../../common/ConditionalContainer/ConditionalContainer'

const CTA_HEIGHT = '40px'

export const ComponentContainer = styled.div`
  width: 100%;
  background: ${colors.black};
  height: ${styling.navigation.height};
  display: flex;
  @media (min-width: ${dimensions.laptop}) {
    height: calc(${styling.navigation.height} + ${CTA_HEIGHT});
  }
`

export const ComponentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  top: 0;
  z-index: 100;
  transition: top 0.3s;
  padding: 0.625rem 1.25rem;
  width: 100%;
  max-width: min(${styling.container.maxWidth.normal}, 100%);
  margin: 0 auto;
  @media (min-width: ${dimensions.tablet}) {
    padding: 1.25rem 2.5rem;
  }
  @media (min-width: ${dimensions.laptop}) {
    top: ${CTA_HEIGHT};
    height: calc(100% - ${CTA_HEIGHT});
  }
`

export const CtaWrapper = styled.div`
  display: flex;
  height: 0;
  width: 100%;
  position: fixed;
  z-index: 101;
  background-color: ${colors.primaryBlack300};
  @media (min-width: ${dimensions.laptop}) {
    top: 0;
    height: ${CTA_HEIGHT};
  }
`

export const LogoImage = styled(Image)`
  background-color: black;
  transition: all 0.5s;
  &:hover {
    @media (min-width: ${dimensions.laptop}) {
      cursor: pointer;
      filter: invert(1);
    }
  }
`

export const NavLinksContainer = styled.div`
  display: none;
  padding: 1rem;
  flex: 1;
  justify-content: center;
  align-items: center;
  @media (min-width: ${dimensions.laptop}) {
    display: flex;
  }
`

export const GeoLanguageContainer = styled.div`
  margin-right: 0.5rem;
  display: none;
  justify-content: center;
  @media (min-width: ${dimensions.laptop}) {
    display: flex;
  }
`

export const GeoItem = styled.section`
  ${typography.bodySm.semiBold};
  color: ${colors.blueSolitudeLight};
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  display: flex;

  &:hover {
    background-color: ${colors.redTorch};
  }
`

// This needs some more love to replicate the design closer
export const OverlayAppLink = styled.div`
  & > #nav-get-in-touch {
    width: 100%;
    padding: 1rem;
  }

  @media (min-width: ${dimensions.tablet}) {
    display: none;
  }
`

export const HamburgerOverlay = styled.div`
  display: inline-block;
  position: absolute;
  height: 100vh;
  width: 100%;
  z-index: 10;
  top: ${styling.navigation.height};
  left: 0;
  background-color: ${colors.white};
  @media (min-width: ${dimensions.tablet}) {
    padding: 0 2.5rem;
  }
`

export const OverlayContainer = styled.div`
  overflow-x: scroll;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  gap: 2rem;
  height: 100%;
  ${typography.bodyLg.semiBold}
  padding: 1rem 2rem 10rem 2rem;

  /* Hide scrollbar for Chrome IE, Edge and Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`

export const ButtonContainer = styled(ConditionalContainer)`
  @media (min-width: ${dimensions.tablet}) {
    padding-right: 2rem;
  }
  @media (min-width: ${dimensions.laptop}) {
    padding-right: 0;
  }
`
