import styled from 'styled-components'

import { fontWeight, styling, typography } from '#styles'

export const Button = styled.button<{
  buttonColor: string
  textColor: string
  size: 'small' | 'medium' | 'full'
  transparent: boolean
}>`
  white-space: nowrap;
  transition: all 0.1s;
  width: ${props => (props.size === 'full' ? '100%' : 'fit-content')};
  padding: ${props =>
    props.transparent
      ? `${props.size === 'small' ? '0.5rem 0rem' : '1rem 0rem'}`
      : `${
          props.size === 'small'
            ? styling.button.padding.sm
            : styling.button.padding.md
        }`};
  border-radius: ${props =>
    props.size === 'small' ? '0.2rem' : styling.button.borderRadius};
  background-color: ${props =>
    props.transparent ? 'transparent' : `${props.buttonColor}`};
  color: ${props => props.textColor};
  font-size: ${typography.bodyMd.semiBold.fontSize};
  font-weight: ${fontWeight.semiBold};
  ${props =>
    props.transparent
      ? `&:hover article {gap: ${styling.container.gap.sm}} cursor: pointer`
      : `&:hover { border-radius: 0.35rem; background-color: rgba(255, 0, 0, 0);
        color: ${props.buttonColor}; box-shadow: inset 0px 0px 0px 2px ${props.buttonColor};} cursor: pointer`};
`

export const IconText = styled.span`
  display: flex;
  justify-content: center;
  white-space: nowrap;
  flex-direction: row;
  align-items: center;
  gap: ${styling.container.gap.xs};
  transition: all 0.1s;
  border-radius: ${styling.button.borderRadius};
`

export const MultiRowButton = styled(Button)`
  width: 100%;
  white-space: normal;
  overflow-wrap: break-word;
  hyphens: auto;
`

export const MultiRowIconText = styled.span`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  gap: ${styling.container.gap.xs};
  transition: all 0.1s;
  border-radius: ${styling.button.borderRadius};
`
