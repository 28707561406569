import '@szhsin/react-menu/dist/index.css'
import '@szhsin/react-menu/dist/transitions/slide.css'

import { useHover, useMenuState } from '@szhsin/react-menu'
import Link from 'next/link'
import React, { FC, useRef } from 'react'

import ChevronDownWhite from '../../../../public/assets/common/chevron-down-white.svg'
import { MenuItem, NavigationItem } from '../../../lib/hooks/useNavigationLinks'
import * as S from './styled'

const DropdownMenu: FC<NavigationItem> = ({ label, path, menuItems }) => {
  const ref = useRef(null)
  const [menuState, toggle] = useMenuState({ transition: true })
  const { anchorProps, hoverProps } = useHover(menuState.state, toggle)

  const renderSubMenu = (item: MenuItem) => (
    <S.NavItemWithSub
      key={item.label}
      label={
        <Link href={item.path} style={{ width: '100%' }} prefetch={false}>
          <S.NavItemWithSubLabel>
            {item.label}
            <S.Chevron
              chevronRight
              alt="chevron arrow right"
              src={ChevronDownWhite}
              priority
            />
          </S.NavItemWithSubLabel>
        </Link>
      }
    >
      {item.subMenuItems?.map(subMenuItem => renderLink(subMenuItem))}
    </S.NavItemWithSub>
  )

  const renderLink = (item: MenuItem) =>
    item.external ? (
      <S.NavItem
        target="_blank"
        rel="noreferrer"
        href={item.path}
        key={item.label}
      >
        {item.label}
      </S.NavItem>
    ) : (
      <Link href={item.path} key={item.label} prefetch={false}>
        <S.NavItem>{item.label}</S.NavItem>
      </Link>
    )

  return (
    <>
      <Link href={path} prefetch={false}>
        <S.DropdownWrapperMenu ref={ref} {...anchorProps}>
          {label}
          <S.Chevron alt="chevron arrow down" src={ChevronDownWhite} priority />
        </S.DropdownWrapperMenu>
      </Link>
      <S.MainLink
        {...hoverProps}
        {...menuState}
        anchorRef={ref}
        onClose={() => toggle(false)}
      >
        {menuItems?.map(item =>
          item.subMenuItems ? renderSubMenu(item) : renderLink(item)
        )}
      </S.MainLink>
    </>
  )
}

export default DropdownMenu
