import Link from 'next/link'
import styled from 'styled-components'

import { colors, styling, typography } from '#styles'

export const CtaContainer = styled.header`
  display: flex;
  left: 0;
  width: 100%;
  height: 100%;
  flex: 1;
  justify-content: center;
`

export const CtaWrapper = styled.div`
  flex-direction: row;
  display: flex;
  width: 100%;
  max-width: ${styling.container.maxWidth.normal};
  justify-content: space-between;
  align-items: center;
  color: white;
  flex: 1 0 0;
`

export const CtaTabLink = styled(Link)`
  ${typography.bodySm.medium};
  max-width: ${styling.container.maxWidth.normal};
  padding: 0 2.5rem;
  margin: 0 auto;

  span {
    &:first-child {
      color: ${colors.white};
      margin-right: 16px;
    }
    &:last-child {
      color: ${colors.redTorch};
    }
  }
`
