import React, { FC } from 'react'

import Button, { ButtonProps, MultiRowButton } from '../Button/Button'

type Props = {
  href: string
  id?: string
} & Omit<ButtonProps, 'onClick'>

export const MultiRowLinkButton: FC<Props> = ({
  href,
  id,
  children,
  ...rest
}) => {
  return (
    <a href={href} target="_blank" rel="noreferrer" id={id}>
      <MultiRowButton {...rest} as="div">
        {children}
      </MultiRowButton>
    </a>
  )
}

const LinkButton: FC<Props> = ({ href, id, children, ...rest }) => {
  return (
    <a href={href} target="_blank" rel="noreferrer" id={id}>
      <Button {...rest} as="div">
        {children}
      </Button>
    </a>
  )
}

export default LinkButton
