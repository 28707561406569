import Link from 'next/link'
import styled from 'styled-components'

import { colors, dimensions, typography } from '#styles'

export const DropdownMenuContainer = styled.div`
  color: ${colors.black};
  width: 100%;
  text-align: left;
  ${typography.bodyMd.semiBold};
`

export const MenuLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  white-space: nowrap;
`

export const ImageWrapper = styled.button<{ open?: boolean }>`
  padding: 1rem;

  & > * {
    transform: ${props => (props.open ? 'rotate(180deg)' : 'rotate(0)')};
    transform-origin: center;
    transition: all 0.15s ease-in-out;
  }
`

export const MainLink = styled(Link)`
  width: 100%;
`

export const MenuItemsContainer = styled.div<{ open: boolean }>`
  display: ${props => (props.open ? 'flex' : 'none')};
  flex-direction: column;
  background-color: ${colors.white};
  color: ${colors.black};
  width: 100%;
  gap: 1rem;
  padding: 1rem 2rem 0 1rem;
  @media (min-width: ${dimensions.laptop}) {
    max-width: 14rem;
  }
`

export const NavItem = styled(Link)`
  display: flex;
  align-items: center;
  // This somewhat arbitrary height is to match the chevron
  height: 3.25rem;
  padding: 1rem 0;
  &:hover {
    background-color: ${colors.redTorch};
  }
`

export const LinkItem = styled.a`
  display: flex;
  align-items: center;
  height: 3.25rem;
  padding: 1rem 0;

  &:hover {
    background-color: ${colors.redTorch};
  }
`

export const UnderlinedLinkItem = styled.a`
  display: flex;
  align-items: center;
  height: 3.25rem;
  padding: 1rem 0;
  color: ${colors.redTorch};
  text-decoration: underline;
`
