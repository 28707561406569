import Link from 'next/link'
import React, { FC } from 'react'

import Button, { ButtonProps } from '../Button/Button'

type Props = {
  href: string
  id?: string | undefined
} & Omit<ButtonProps, 'onClick'>

const NavButton: FC<Props> = ({ href, children, id, ...rest }) => {
  return (
    <Link href={href} id={id} target="_self" prefetch={false}>
      <Button {...rest} as="div">
        {children}
      </Button>
    </Link>
  )
}

export default NavButton
