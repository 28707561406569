import { useRouter } from 'next/router'
import React, { FC, useState } from 'react'

import i18nextConfig from '../../../../next-i18next.config'
import { locales } from '../../../lib/constants'
import Globe from './Globe'
import * as S from './styled'

type Locale = keyof typeof locales

const parseLocale = (locale?: string): Locale => {
  if (locale?.slice(0, 2) === 'en') {
    return 'en'
  }
  if (locale?.slice(0, 2) === 'es') {
    return 'es'
  }
  return locale as Locale
}
export const findBestNavLocale = (loc: string, currentLoc: string) => {
  if (loc === 'es' || loc === 'en') {
    const country = currentLoc.slice(3)

    const locale = i18nextConfig.i18n.locales.find(
      l => l.slice(0, 2) === loc && l.slice(3) === country
    )
    if (locale) return locale
    if (loc === 'es') return 'es-es'
    return 'en-us'
  }
  return loc
}

type Props = {
  reverse?: boolean
}

const LanguageDropdown: FC<Props> = ({ reverse }) => {
  const router = useRouter()

  const [isOpen, setIsOpen] = useState(false)

  const onSelect = (locale: string) => {
    // remember choice
    document.cookie = `NEXT_LOCALE=${locale}; max-age=31536000;`
    setIsOpen(false)
  }

  const createLanguages = () =>
    Object.entries(locales)
      .filter(([loc]) => loc !== router.locale)
      .map(([locale, label], idx, options) => {
        const loc = findBestNavLocale(locale, router.locale ?? 'en-us')
        return (
          <li key={loc} style={{ width: '100%' }}>
            <S.LanguageLink
              open={isOpen}
              href={{
                pathname: router.pathname,
                query: router.query,
              }}
              locale={loc}
              onClick={() => onSelect(loc)}
              position={idx + 1}
              prefetch={false}
              options={options.length}
            >
              <S.LanguageText>{label}</S.LanguageText>
            </S.LanguageLink>
          </li>
        )
      })

  return (
    <S.Container>
      <S.ClickOutside
        open={isOpen}
        onClick={e => {
          e.stopPropagation()
          setIsOpen(false)
        }}
      />
      <S.OpenMenuButton
        type="button"
        onClick={() => {
          setIsOpen(!isOpen)
        }}
      >
        <Globe />
        <S.LanguageText>{locales[parseLocale(router.locale)]}</S.LanguageText>
      </S.OpenMenuButton>
      <S.Options open={isOpen} reverse={reverse}>
        {createLanguages()}
      </S.Options>
    </S.Container>
  )
}

export default LanguageDropdown
