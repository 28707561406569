import Image from 'next/image'
import React, { FC, useState } from 'react'

import ChevronDown from '../../../../public/assets/common/chevron-down.svg'
import { NavigationItem } from '../../../lib/hooks/useNavigationLinks'
import * as S from './styled'

type Props = {
  setHamburgerIsOpen: React.Dispatch<React.SetStateAction<boolean>>
} & NavigationItem

const MobileNavMenu: FC<Props> = ({
  label,
  path,
  menuItems,
  setHamburgerIsOpen,
}) => {
  const [open, setOpen] = useState(false)

  return (
    <S.DropdownMenuContainer>
      <S.MenuLabelWrapper>
        <S.MainLink
          onClick={() => setHamburgerIsOpen(false)}
          href={path}
          prefetch={false}
        >
          {label}
        </S.MainLink>
        <S.ImageWrapper onClick={() => setOpen(prev => !prev)} open={open}>
          {menuItems.length !== 0 && (
            <Image
              alt="chevron arrow down"
              src={ChevronDown}
              priority
              height={20}
              width={25}
            />
          )}
        </S.ImageWrapper>
      </S.MenuLabelWrapper>
      <S.MenuItemsContainer open={open}>
        {menuItems.map(item =>
          item.subMenuItems ? (
            <MobileNavMenu
              key={item.label}
              label={item.label}
              path={item.path}
              menuItems={item.subMenuItems}
              setHamburgerIsOpen={setHamburgerIsOpen}
            />
          ) : item.external ? (
            <S.LinkItem
              key={item.label}
              href={item.path}
              target="_blank"
              rel="noreferrer"
              onClick={() => setHamburgerIsOpen(false)}
            >
              {item.label}
            </S.LinkItem>
          ) : (
            <S.NavItem
              key={item.label}
              href={item.path}
              onClick={() => setHamburgerIsOpen(false)}
            >
              {item.label}
            </S.NavItem>
          )
        )}
      </S.MenuItemsContainer>
    </S.DropdownMenuContainer>
  )
}

export default MobileNavMenu
