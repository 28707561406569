import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import React, { FC, useMemo } from 'react'
import { useChat } from 'react-live-chat-loader'

import { colors, dimensions } from '#styles'

import brick from '../../../../public/assets/GetInTouch/hand-retrieving-power-bank.png'
import { Anchors, Paths } from '../../../lib/constants'
import Button from '../../common/Button/Button'
import NavButton from '../../common/NavButton/NavButton'
import * as S from './styled'

type Props = {}

const GetInTouch: FC<Props> = () => {
  const { t } = useTranslation('common')
  const [_, loadChat] = useChat()

  const router = useRouter()

  const isPartnershipPage = useMemo(
    () => router.route.includes('/partnership'),
    [router.route]
  )

  return (
    <S.ComponentWrapper id={Anchors.GET_IN_TOUCH}>
      <S.ImageContainer>
        <S.StyledImage
          src={brick}
          alt={t('get_in_touch.image_alt')}
          sizes={`(min-width: ${dimensions.tablet}) 50vw, 100vw`}
          placeholder="blur"
        />
      </S.ImageContainer>
      <S.FormContainer>
        <S.TextWrapper>
          <S.TextHeader>{t('get_in_touch.header')}</S.TextHeader>
          {isPartnershipPage ? (
            <NavButton
              id="primary-apply-network"
              buttonColor={colors.redTorch}
              href={Paths.PARTNERSHIP_APPLY}
            >
              {t('get_in_touch.button.intercom')}
            </NavButton>
          ) : (
            <Button
              id="primary-contact-bottom_banner"
              onClick={() => loadChat({ open: true })}
            >
              {t('get_in_touch.button.intercom')}
            </Button>
          )}
        </S.TextWrapper>
      </S.FormContainer>
    </S.ComponentWrapper>
  )
}

export default GetInTouch
