import styled from 'styled-components'

import { dimensions } from '#styles'

export const ConditionalWrapper = styled.div<{
  mobile?: boolean
  tablet?: boolean
  laptop?: boolean
}>`
  display: ${props => (props.mobile ? 'inherit' : 'none')};
  @media (min-width: ${dimensions.tablet}) {
    display: ${props => (props.tablet ? 'inherit' : 'none')};
  }
  @media (min-width: ${dimensions.laptop}) {
    display: ${props => (props.laptop ? 'inherit' : 'none')};
  }
`
