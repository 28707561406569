import { FC, ReactNode } from 'react'

import { PageProps } from '../../types/pages'
import Footer from './Footer/Footer'
import Menubar from './Menubar/Menubar'

type Props = {
  children: ReactNode
} & PageProps

const Layout: FC<Props> = ({ children, applessOnly, button }) => (
  <>
    <Menubar applessOnly={applessOnly} button={button} />
    {children}
    <Footer />
  </>
)

export default Layout
