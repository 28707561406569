import { useTranslation } from 'next-i18next'

import { Links, Paths, SolutionsSlugs } from '../constants'
import { joinPath } from '../helpers'

export type NavigationItem = {
  label: string
  path: string
  menuItems: MenuItem[]
}

export type MenuItem = {
  label: string
  path: string
  external?: boolean
  subMenuItems?: { label: string; path: string; external?: boolean }[]
}

export type CtaItem = {
  label: string
  path: string
}

export const useNavigationLinksFooter = () => {
  const { t } = useTranslation('common')

  const rent: NavigationItem = {
    label: t('navigation.links.brick'),
    path: Paths.RENT,
    menuItems: [
      { label: t('navigation.anchors.rent_a_brick'), path: Paths.RENT },
      {
        label: t('navigation.links.articles'),
        path: Links.ARTICLES,
        external: true,
      },
      { label: t('navigation.anchors.insights'), path: Paths.RENT },
      {
        label: t('navigation.anchors.press'),
        path: Links.PRESS,
        external: true,
      },
    ],
  }

  const getAStation: NavigationItem = {
    label: t('navigation.links.venue'),
    path: Paths.GET_A_STATION,
    menuItems: [
      {
        label: t('navigation.links.station'),
        path: Paths.GET_A_STATION,
      },
      // {
      //   label: t('navigation.anchors.wish_a_venue'),
      //   path: Links.WISH_A_VENUE,
      //   external: true,
      // },
      {
        label: t('navigation.anchors.venue_charging_solutions'),
        path: Paths.GET_A_STATION,
      },
    ],
  }

  const partnership: NavigationItem = {
    label: t('navigation.links.partnership'),
    path: Paths.PARTNERSHIP,
    menuItems: [
      {
        label: t('navigation.anchors.partner_with_us'),
        path: Paths.PARTNERSHIP,
      },
      {
        label: t('navigation.anchors.our_products'),
        path: Paths.PARTNERSHIP_PRODUCTS,
      },
      {
        label: t('navigation.anchors.why_brick'),
        path: Paths.PARTNERSHIP_WHY,
      },
      {
        label: t('navigation.anchors.apply'),
        path: Paths.PARTNERSHIP_APPLY,
      },
    ],
  }

  const company: NavigationItem = {
    label: t('navigation.links.legal'),
    path: Paths.ABOUT,
    menuItems: [
      {
        label: t('navigation.anchors.privacy'),
        path: Paths.PRIVACY,
      },
      {
        label: t('navigation.anchors.cookie_policy'),
        path: Paths.PRIVACY_COOKIES,
      },
      {
        label: t('navigation.anchors.terms'),
        path: Paths.TERMS,
      },
      {
        label: t('navigation.anchors.your_data'),
        path: Paths.PRIVACY_YOUR_DATA,
      },
    ],
  }

  return [rent, getAStation, partnership, company]
}

export const useNavigationLinksHeader = () => {
  const { t } = useTranslation('common')

  const rent: NavigationItem = {
    label: t('navigation.links.rent'),
    path: Paths.RENT,
    menuItems: [
      { label: t('navigation.anchors.how_to'), path: Paths.RENT },

      { label: t('navigation.anchors.get_the_app'), path: Paths.RENT },
      {
        label: t('navigation.anchors.pricing'),
        path: Paths.RENT_PRICING,
      },
    ],
  }

  const getAStation: NavigationItem = {
    label: t('navigation.links.get_brick'),
    path: Paths.GET_A_STATION,
    menuItems: [
      {
        label: t('navigation.anchors.our_stations'),
        path: Paths.GET_A_STATION,
      },
      {
        label: t('navigation.anchors.why_get_a_station'),
        path: Paths.GET_A_STATION,
      },
      {
        label: t('navigation.anchors.brick_for_brands'),
        path: Paths.BRICK_FOR_BRANDS,
      },
      // {
      //   label: t('navigation.anchors.wish_a_venue'),
      //   path: Links.WISH_A_VENUE,
      //   external: true,
      // },
      {
        label: t('navigation.anchors.solutions'),
        path: Paths.GET_A_STATION,
        subMenuItems: [
          {
            label: t('navigation.slugs.restaurants'),
            path: joinPath(Paths.SOLUTIONS, SolutionsSlugs.RESTAURANTS),
          },
          {
            label: t('navigation.slugs.bars'),
            path: joinPath(Paths.SOLUTIONS, SolutionsSlugs.BARS),
          },
          {
            label: t('navigation.slugs.malls'),
            path: joinPath(Paths.SOLUTIONS, SolutionsSlugs.MALLS),
          },
          {
            label: t('navigation.slugs.arenas'),
            path: joinPath(Paths.SOLUTIONS, SolutionsSlugs.ARENAS),
          },
          {
            label: t('navigation.slugs.airports'),
            path: joinPath(Paths.SOLUTIONS, SolutionsSlugs.AIRPORTS),
          },
          {
            label: t('navigation.slugs.venues'),
            path: joinPath(Paths.SOLUTIONS, SolutionsSlugs.VENUES),
          },
        ],
      },
    ],
  }

  const partnership: NavigationItem = {
    label: t('navigation.links.partnership'),
    path: Paths.PARTNERSHIP,
    menuItems: [
      {
        label: t('navigation.anchors.partner_with_us'),
        path: Paths.PARTNERSHIP,
      },
      {
        label: t('navigation.anchors.our_products'),
        path: Paths.PARTNERSHIP_PRODUCTS,
      },
      {
        label: t('navigation.anchors.why_brick'),
        path: Paths.PARTNERSHIP_WHY,
      },
      {
        label: t('navigation.anchors.apply'),
        path: Paths.PARTNERSHIP_APPLY,
      },
      {
        label: t('navigation.links.articles'),
        path: Links.ARTICLES,
        external: true,
      },
    ],
  }

  const company: NavigationItem = {
    label: t('navigation.links.about'),
    path: Paths.ABOUT,
    menuItems: [
      {
        label: t('navigation.anchors.about_us'),
        path: Paths.ABOUT,
      },
      {
        label: t('navigation.anchors.privacy'),
        path: Paths.PRIVACY,
      },
      {
        label: t('navigation.anchors.press'),
        path: Links.PRESS,
        external: true,
      },
      {
        label: t('navigation.anchors.terms'),
        path: Paths.TERMS,
      },
      {
        label: t('navigation.anchors.support'),
        path: Paths.SUPPORT,
      },
    ],
  }

  return [rent, getAStation, partnership, company]
}

export const useNavigationLinksCta = () => {
  const { t } = useTranslation('common')
  const getAStation: CtaItem = {
    label: t('navigation.links.station'),
    path: Paths.GET_A_STATION,
  }

  const brickPartner: CtaItem = {
    label: t('navigation.brick_partner'),
    path: Paths.PARTNERSHIP_APPLY,
  }

  return [getAStation, brickPartner]
}
