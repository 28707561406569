import React, { FC, ReactNode } from 'react'

import * as S from './styled'

type Props = {
  tablet?: boolean
  mobile?: boolean
  laptop?: boolean
  children: ReactNode
  style?: React.CSSProperties
}

const ConditionalContainer: FC<Props> = ({
  mobile,
  tablet,
  laptop,
  children,
  style,
}) => {
  return (
    <S.ConditionalWrapper
      mobile={mobile}
      tablet={tablet}
      laptop={laptop}
      style={style}
    >
      {children}
    </S.ConditionalWrapper>
  )
}

export default ConditionalContainer
