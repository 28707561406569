const { resolve } = require('path')
const locales = require('./locales.config')

// if there are multiple versions of a language, place the more general one first
/** @type {import('next-i18next').UserConfig} */
module.exports = {
  i18n: {
    defaultLocale: 'default',
    locales,
    localeDetection: false,
  },
  localePath: resolve('./public/locales'),
  returnObjects: true,
}
