import { ControlledMenu, MenuItem, SubMenu } from '@szhsin/react-menu'
import Image from 'next/image'
import styled from 'styled-components'

import { colors } from '#styles'

export const DropdownWrapperMenu = styled.div`
  color: ${colors.white};
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 1rem;
`

export const MainLink = styled(ControlledMenu)`
  & .szh-menu {
    background-color: ${colors.black};
    padding: 0;
    padding-top: 0.5rem;
    border-radius: 0;
  }
`

export const NavItem = styled(MenuItem)`
  padding: 1rem;
  color: ${colors.white};
  &:hover {
    background-color: ${colors.redTorch};
    transition: 0.1s ease-in;
  }
`

export const NavItemWithSub = styled(SubMenu)`
  transition: 0.1s ease-in;
  color: ${colors.white};

  // So much for "non-opinionated" library :P

  & .szh-menu__item::after {
    display: none;
  }

  & .szh-menu__submenu {
    padding: 0;
  }

  & .szh-menu__item {
    padding: 1rem;
  }

  & .szh-menu__item--hover {
    background-color: ${colors.redTorch};
  }

  & .szh-menu {
    padding: 0;
  }
  &:hover {
    background-color: ${colors.redTorch};
  }
`

export const NavItemWithSubLabel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const Chevron = styled(Image)<{ chevronRight?: boolean }>`
  margin-left: 1rem;
  height: 0.5rem;
  width: 1rem;
  transform: ${props => (props.chevronRight ? 'rotate(270deg)' : 'none')};
`
