import React, { ButtonHTMLAttributes, FC, ReactNode } from 'react'

import { colors } from '#styles'

import * as S from './styled'

export type ButtonProps = {
  id?: string
  buttonColor?: string
  transparent?: boolean
  textColor?: string
  size?: 'small' | 'medium' | 'full'
  children: ReactNode
  onClick?: () => void
  as?: string
  type?: 'button' | 'submit' | 'reset'
  style?: React.CSSProperties
} & ButtonHTMLAttributes<HTMLButtonElement>

export const MultiRowButton: FC<ButtonProps> = ({
  id,
  buttonColor = colors.redTorch,
  transparent = false,
  textColor = colors.white,
  size = 'medium',
  children,
  onClick,
  as,
  type = 'button',
  style,
}) => {
  return (
    // @ts-expect-error - hard to type properly
    <S.MultiRowButton
      id={id}
      type={type}
      // @ts-expect-error - hard to type properly
      as={as}
      onClick={onClick}
      buttonColor={buttonColor}
      textColor={textColor}
      size={size}
      transparent={transparent}
      style={style}
    >
      <S.MultiRowIconText>{children}</S.MultiRowIconText>
    </S.MultiRowButton>
  )
}

const Button: FC<ButtonProps> = ({
  id,
  buttonColor = colors.redTorch,
  transparent = false,
  textColor = colors.white,
  size = 'medium',
  children,
  onClick,
  as,
  type = 'button',
  style,
  ...rest
}) => {
  return (
    // @ts-expect-error - hard to type properly
    <S.Button
      {...rest}
      id={id}
      type={type}
      // @ts-expect-error - hard to type properly
      as={as}
      onClick={onClick}
      buttonColor={buttonColor}
      textColor={textColor}
      size={size}
      transparent={transparent}
      style={style}
    >
      <S.IconText>{children}</S.IconText>
    </S.Button>
  )
}

export default Button
