import { useTranslation } from 'next-i18next'
import React from 'react'

import { Paths } from '../../../lib/constants'
import { CtaContainer, CtaTabLink, CtaWrapper } from './styled'

const CtaTab: React.FC = () => {
  const { t } = useTranslation('common')
  const tabs = [
    {
      id: 'primary-apply-banner_station',
      label: [
        t('cta.order_a_station.section0'),
        t('cta.order_a_station.section1'),
      ],
      path: Paths.GET_A_STATION,
    },
    {
      id: 'primary-apply-banner_partner',
      label: [
        t('cta.become_a_partner.section0'),
        t('cta.become_a_partner.section1'),
      ],
      path: Paths.PARTNERSHIP_APPLY,
    },
  ]

  return (
    <CtaContainer>
      <CtaWrapper>
        {tabs.map(tab => (
          <CtaTabLink
            key={tab.label.join()}
            href={tab.path}
            id={tab.id}
            prefetch={false}
          >
            <span>{tab.label[0]}</span>
            <span>{tab.label[1]}</span>
          </CtaTabLink>
        ))}
      </CtaWrapper>
    </CtaContainer>
  )
}

export default CtaTab
